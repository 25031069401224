<template>
  <div class="grid">
    <div class="col-12 md:col-6 lg:col-3">
      <Card class="statsCard">
        <template #content>
          <template v-if="loading">
            <Skeleton width="5rem" height="2rem" class="mb-2"></Skeleton>
            <Skeleton width="10rem" height="2rem" class="mb-2"></Skeleton>
          </template>
          <template v-else>
            <h2>{{ usersRegisteredDashboard.users }}</h2>
            <h6>{{ $t("dashboard.registrationsToday") }}</h6>
          </template>
        </template>
      </Card>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <Card class="statsCard">
        <template #content>
          <template v-if="loading">
            <Skeleton width="5rem" height="2rem" class="mb-2"></Skeleton>
            <Skeleton width="10rem" height="2rem" class="mb-2"></Skeleton>
          </template>
          <template v-else>
            <h2>{{ applicationsDashboard.applications }}</h2>
            <h6>{{ $t("dashboard.applicationsToday") }}</h6>
          </template>
        </template>
      </Card>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <Card class="statsCard">
        <template #content>
          <template v-if="loading">
            <Skeleton width="5rem" height="2rem" class="mb-2"></Skeleton>
            <Skeleton width="10rem" height="2rem" class="mb-2"></Skeleton>
          </template>
          <template v-else>
            <h2>{{ casesDashboard.cases }}</h2>
            <h6>{{ $t("dashboard.activeCases") }}</h6>
          </template>
        </template>
      </Card>
    </div>

    <div class="col-12 md:col-6 lg:col-3">
      <Card class="statsCard" style="background-color: #0094da; color: white">
        <template #content>
          <div class="grid">
            <div class="col">
              <template v-if="loading">
                <Skeleton width="3rem" height="2rem" class="mb-2"></Skeleton>
                <Skeleton width="5rem" height="2rem" class="mb-2"></Skeleton>
              </template>
              <template v-else>
                <h2>{{ companiesDashboard.companies }}</h2>
                <p>{{ $t("dashboard.companies") }}</p>
              </template>
            </div>
            <div class="col">
              <template v-if="loading">
                <Skeleton width="3rem" height="2rem" class="mb-2"></Skeleton>
                <Skeleton width="5rem" height="2rem" class="mb-2"></Skeleton>
              </template>
              <template v-else>
                <h2>{{ auditPlansDashboard.auditPlans }}</h2>
                <p>{{ $t("dashboard.audits") }}</p>
              </template>
            </div>
            <div class="col">
              <template v-if="loading">
                <Skeleton width="3rem" height="2rem" class="mb-2"></Skeleton>
                <Skeleton width="5rem" height="2rem" class="mb-2"></Skeleton>
              </template>
              <template v-else>
                <h2>{{ certificationsDashboard.certifications }}</h2>
                <p>{{ $t("dashboard.certifications") }}</p>
              </template>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
  <div class="grid nested-grid ai-stretch vertical-container">
    <div class="col-12 md:col-9 lg:col-9">
      <Notifications></Notifications>
    </div>
    <div class="col-12 md:col-3 lg:col-3">
      <div class="grid flex-column">
        <div class="col">
          <Card>
            <template #content>
              <div class="grid grid-nogutter">
                <div class="col-8">
                  <div class="grid">
                    <div class="col">
                      <h6>
                        {{ $t("dashboard.totalVisits") }} /
                        {{ $t("dashboard.visitsToday") }}
                      </h6>
                    </div>
                    <div class="col-12 pt-0">
                      <h3>
                        {{ visitsDashboard.totalVisits }} /
                        {{ visitsDashboard.dailyVisits }}
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <Chart type="pie" :data="chartData" :options="lightOptions" />
                </div>
              </div>
            </template>
          </Card>
        </div>
        <div class="col-12">
          <Card>
            <template #title>
              <h6>{{ $t("dashboard.topLocation") }}</h6>
            </template>
            <template #content>
              <template v-if="loading">
                <Skeleton width="7rem" height="2rem" class="mb-2"></Skeleton>
              </template>
              <template v-else>
                <div class="grid">
                  <div class="col-5">
                    <h5>
                      {{ citiesDashboard.length }} {{ $t("dashboard.cities") }}
                    </h5>
                  </div>
                  <div class="col-7 p-0">
                    <div id="map" style="width: 100%; height: 15vh"></div>
                  </div>
                </div>
              </template>
            </template>
          </Card>
        </div>
        <div class="col-12">
          <Card>
            <template #title>
              <h6>
                {{ $t("dashboard.regPerDay") }} ({{
                  $t("dashboard.lastSevenDays")
                }})
              </h6>
            </template>
            <template #content>
              <Chart
                type="bar"
                :data="registeredChart"
                :options="basicOptions"
              />
            </template>
          </Card>
        </div>
        <div class="col-12">
          <Card>
            <template #title>
              <h6>
                {{ $t("dashboard.auditsPerDay") }} ({{
                  $t("dashboard.lastSevenDays")
                }})
              </h6>
            </template>
            <template #content>
              <Chart
                type="line"
                :data="auditPlansGraph"
                :options="basicOptions"
              />
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notifications from "./Notifications.vue";
import { mapGetters } from "vuex";
import { Loader } from "@googlemaps/js-api-loader";

export default {
  components: {
    Notifications,
  },
  data() {
    return {
      loading: true,
      chartData: {
        labels: [
          this.$t("dashboard.totalVisits"),
          this.$t("dashboard.visitsToday"),
        ],
        datasets: [
          {
            data: [0, 0],
            backgroundColor: ["#42A5F5", "#66BB6A"],
            hoverBackgroundColor: ["#64B5F6", "#81C784"],
            tooltip: {
              callbacks: {
                label: function (labelData) {
                  const title = `${labelData.label}: ${labelData.formattedValue}`;
                  return title.split(" ");
                },
              },
            },
          },
        ],
      },
      lightOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },

      registeredChart: {
        labels: [],
        datasets: [
          {
            data: [],
            fill: true,
            borderColor: "#42A5F5",
            tension: 0.4,
            backgroundColor: "rgba(255,167,38,0.2)",
          },
        ],
      },
      auditPlansGraph: {
        labels: [],
        datasets: [
          {
            data: [],
            fill: false,
            borderColor: "#42A5F5",
            tension: 0.4,
            backgroundColor: "rgba(255,167,38,0.2)",
          },
        ],
      },

      basicOptions: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            title: {
              display: false,
            },
            ticks: {
              display: false,
              stepSize: 1,
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
              stepSize: 1,
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "usersRegisteredDashboard",
      "applicationsDashboard",
      "certificationsDashboard",
      "casesDashboard",
      "auditPlansDashboard",
      "companiesDashboard",
      "citiesDashboard",
      "visitsDashboard",
      "auditPlansGraphDashboard",
      "registeredGraphDashboard",
    ]),
  },
  async mounted() {
    const today = this.$dayjs().format("DD/MM/YYYY");
    const subtracted = this.$dayjs().subtract(7, "days").format("DD/MM/YYYY");
    this.loading = true;
    await Promise.all([
      this.$store.dispatch("getUsersRegisteredDashboard", today),
      this.$store.dispatch("getApplicationsDashboard", today),
      this.$store.dispatch("getCertificationsDashboard"),
      this.$store.dispatch("getCasesDashboard"),
      this.$store.dispatch("getAuditPlansDashboard"),
      this.$store.dispatch("getCompaniesDashboard"),
      this.$store.dispatch("getCitiesDashboard"),
      this.$store.dispatch("getVisitsDashboard"),
      this.$store.dispatch("usersRegistrationsDashboardPeriod", {
        date: subtracted,
        dateEnd: today,
      }),
      this.$store.dispatch("auditPlansDashboardPeriod", {
        date: subtracted,
        dateEnd: today,
      }),
    ]);
    this.auditPlansGraph.labels = this.auditPlansGraphDashboard.labels;
    this.auditPlansGraph.datasets[0].data = this.auditPlansGraphDashboard.data;

    this.registeredChart.labels = this.registeredGraphDashboard.labels;
    this.registeredChart.datasets[0].data = this.registeredGraphDashboard.data;

    //Load pie chart data
    this.chartData.datasets[0].data = [
      this.visitsDashboard.totalVisits,
      this.visitsDashboard.dailyVisits,
    ];

    this.loading = false;
    //Load google map
    const loader = new Loader({ apiKey: process.env["VUE_APP_GOOGLE_KEY"] });
    await loader.load();

    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: {
        lat: 37.98381,
        lng: 23.727539,
      },
      zoom: 7,
    });
    let bounds = new window.google.maps.LatLngBounds();
    this.citiesDashboard.forEach((el) => {
      let coords = new window.google.maps.LatLng(el.latitude, el.longitude);
      // let infoWindow = new window.google.maps.InfoWindow({
      //     content: el.city,
      //   })
      new window.google.maps.Marker({
        position: coords,
        animation: window.google.maps.Animation.DROP,
        map,
      });
      // infoWindow.open({
      //   anchor: marker,
      //   map
      // })
      bounds.extend(coords);
    });

    map.fitBounds(bounds);
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: bold;
  margin: 0;
}

h6 {
  margin: 0;
}

h3 {
  margin: 0;
}

.statsCard {
  height: 90%;
}
</style>
