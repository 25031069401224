<template>
  <div class="grid align-items-stretch vertical-container">
    <div class="col-12 md:col-6 lg:col-6">
      <Notifications></Notifications>
    </div>
    <div class="col-12 md:col-6 lg:col-6">
      <Applications
        v-if="
          checkRole([
            'head-application-reviewer',
            'application-reviewer',
            'sales',
          ])
        "
        :pageData="applicationData"
      ></Applications>
      <Audits
        v-if="
          checkRole([
            'audit-planner',
            'auditor',
            'head-audit-planner',
            'manager-for-certification-decision',
            'certification-manager',
            'head-manager-for-audit-assignment',
            'manager-for-audit-assignment',
            'head-technical-reviewer',
            'technical-reviewer',
            'accounting',
          ])
        "
        :pageData="auditsData"
      ></Audits>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Notifications from "./Notifications.vue";
import Applications from "./Applications.vue";
import Audits from "./Audits.vue";
export default {
  name: "Dashboard all roles",
  components: {
    Notifications,
    Applications,
    Audits,
  },
  computed: {
    ...mapGetters(["checkRole"]),
    ...mapState(["CaseStatuses"]),
    auditsData() {
      let data = {};
      if (this.checkRole(["auditor"])) {
        data.title = this.$t("dashboard.assignedCases");
        data.buttonWord = this.$t("dashboard.audits");
        data.route = "/audits";
      } else if (this.checkRole(["audit-planner", "head-audit-planner"])) {
        data.title = this.$t("dashboard.casesToPlan");
        data.buttonWord = this.$t("dashboard.calendar");
        data.route = "/calendar";
      } else if (
        this.checkRole([
          "manager-for-certification-decision"
        ])
      ) {
        data.title = this.$t("dashboard.casesCertifications");
        data.route = "/auditCases";
        data.buttonWord = this.$t("dashboard.auditCases");
        data.status = this.CaseStatuses.TO_BE_CERTIFIED.name
      } else if(this.checkRole(["certification-manager"])) {
        data.title = this.$t("dashboard.casesCertifications");
        data.route = "/auditCases";
      } else if (
        this.checkRole(["head-technical-reviewer", "technical-reviewer"])
      ) {
        data.title = this.$t("dashboard.auditCasesToView");
        data.buttonWord = this.$t("dashboard.auditCases");
        data.route = "/auditCases";
      } else if (this.checkRole(["accounting"])) {
        data.title = this.$t("dashboard.issueInvoice");
        data.buttonWord = this.$t("dashboard.viewInvoices");
        data.route = "/invoices";
        data.invoiceStatus = "pending"
      } else if (
        this.checkRole([
          "head-manager-for-audit-assignment",
          "manager-for-audit-assignment",
        ])
      ) {
        data.title = this.$t("dashboard.caseToAddAuditor");
        data.buttonWord = this.$t("dashboard.calendar");
        data.route = "/calendar";
      }

      return data;
    },
    applicationData() {
      let data = {};
      if (
        this.checkRole(["head-application-reviewer", "application-reviewer"])
      ) {
        data.title = this.$t("dashboard.applicationsForReview");
        data.route = "/applications";
      } else if (this.checkRole(["sales"])) {
        data.title = this.$t("dashboard.contractsToCreate");
        data.route = "/createContracts";
      }

      return data;
    },
  },
};
</script>
