<template>
  <Dialog
    :showHeader="false"
    v-model:visible="showWelcome"
    :draggable="false"
    :modal="true"
    :style="{ width: '40vw' }"
    id="welcome"
  >
  <img style="width:100%;" src="/layout/images/welcome.jpg" alt="">
  <div class="flex flex-column justify-content-center text-center text-lg px-5">
    <p class="font-bold pt-3">{{$t("global.welcome")}}</p>
    <p class="text-lg pt-2">{{$t("global.inPlatform")}}</p>
    <span class="text-lg pt-2">{{$t("global.startProcess")}} <b>"{{$t("dashboard.newApplication")}}"</b></span>
  </div>
    <template #footer>
      <Button
        :label="$t('global.cancel')"
        class="p-button-danger"
        @click="closeWelcome"
      />
      <Button :label="$t('dashboard.newApplication')" @click="$router.push('/createApplication')" autofocus />
    </template>
  </Dialog>
  <div class="grid flex-column">
    <div class="col">
      <CompanyInformation></CompanyInformation>
    </div>
    <div class="col">
      <div class="grid nested-grid">
        <div class="col-12 md:col-6 lg:col-6">
          <Notifications></Notifications>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
          <div class="grid flex-column">
            <div class="col">
              <Card>
                <template #title
                  >{{ $t("dashboard.yourApplications") }}
                </template>

                <template #content>
                  <div v-if="loading" class="flex align-content-center m-1">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                  <div
                    v-else-if="!loading && showProgressApplications.length == 0"
                  >
                    <p>{{ $t("dashboard.noApplications") }}</p>
                  </div>
                  <div v-else class="grid grid-nogutter align-items-end">
                    <div
                      class="col-12 pr-6"
                      v-for="application in showProgressApplications"
                      :key="application.id"
                    >
                      <div class="grid flex-column">
                        <div class="col pb-0">
                          <span class="text-lg"
                            >{{ $t("dashboard.yourApplication") }} #{{
                              application.id
                            }}</span
                          >

                          <!-- <p>
                            {{ $t("applications.yourApplicationConfirmation") }}
                          </p> -->
                        </div>
                        <div class="col">
                          <ProgressBar
                            style="height: 1.2rem"
                            :value="
                              createProgressNumber(
                                application.completionPercentage
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #footer>
                  <div style="text-align: right">
                    <router-link to="/createApplication">
                      <Button :label="$t('dashboard.newApplication')"></Button>
                    </router-link>
                  </div>
                </template>
              </Card>
            </div>
            <div class="col">
              <Card>
                <template #title>{{ $t("dashboard.yourCases") }} </template>
                <template #content>
                  <div v-if="loading" class="flex align-content-center m-1">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                  <div v-else-if="!loading && showProgressCases.length == 0">
                    <p>{{ $t("dashboard.noCases") }}</p>
                  </div>
                  <div v-else class="grid grid-nogutter align-items-end">
                    <div
                      class="col-12 pr-6"
                      v-for="caseValue in showProgressCases"
                      :key="caseValue.id"
                    >
                      <div class="grid flex-column">
                        <div class="col pb-0">
                          <span class="text-lg"
                            >{{ $t("dashboard.yourCase") }} #{{
                              caseValue.id
                            }}</span
                          >

                          <!-- <p>
                            {{ $t("applications.yourApplicationConfirmation") }}
                          </p> -->
                        </div>
                        <div class="col">
                          <ProgressBar
                            style="height: 1.2rem"
                            :value="
                              createProgressNumber(
                                caseValue.completionPercentage
                              )
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Card>
            </div>
            <div class="col">
              <Card
                ><template #title>{{
                  $t("dashboard.yourCertifications")
                }}</template>

                <template #content>
                  <div v-if="loading" class="flex align-content-center m-1">
                    <ProgressSpinner style="width: 50px; height: 50px" />
                  </div>
                  <div v-else-if="!loading && certifications.length == 0">
                    <p>{{ $t("dashboard.noCertifications") }}</p>
                  </div>
                  <div v-else-if="!loading && certifications.length > 0">
                    <div class="grid flex-column pt-3">
                      <div
                        class="col py-2 transition-colors transition-duration-500 hover:surface-200 border-round cursor-pointer"
                        v-for="(item, index) in certifications"
                        :key="index"
                      >
                        <router-link
                          :to="'/certifications?certId=' + item.id"
                          custom
                          v-slot="{ navigate, href }"
                        >
                          <div :href="href" @click="navigate">
                            <div class="flex align-items-center">
                              <div
                                class="w-1rem h-1rem border-round mr-2"
                                style="background-color: #7d63f3"
                              ></div>
                              <b
                                >{{ item.companyName }} -
                                {{ item.companyRepresentative }}</b
                              >
                            </div>
                            <p class="opacity-40 ml-4">
                              {{
                                $dayjs(item.certificationDate, 'DD/MM/YYYY HH:mm:ss').format("HH:mm")
                              }}
                              ,
                              {{
                                $dayjs(item.certificationDate, 'DD/MM/YYYY HH:mm:ss').format(
                                  "DD MMMM, YYYY"
                                )
                              }}
                            </p>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompanyInformation from "../CompanyInformation.vue";
import Notifications from "./Notifications.vue";

export default {
  name: "Dashboard client",
  components: {
    CompanyInformation,
    Notifications,
  },
  data() {
    return {
      value1: 50,
      loading: false,
      showProgressApplications: [],
      showProgressCases: [],
      showWelcome: false
    };
  },
  computed: {
    ...mapGetters(["certifications", "applications", "cases"]),
  },
  async mounted() {
    this.loading = true;
    await Promise.all([
      await this.$store.dispatch("certifications", {}),
      await this.$store.dispatch("applications", { status: "" }),
      await this.$store.dispatch("cases", {}),
    ]);
    this.showWelcome = this.applications.length > 0 ? false : true;
    this.loading = false;
  },
  watch: {
    applications() {
      this.showProgressApplications = [];
      this.applications.forEach((el) => {
        if (el.completionPercentage < 100) {
          this.showProgressApplications.push(el);
        }
      });
    },
    cases() {
      this.cases.forEach((el) => {
        if (el.completionPercentage < 100) {
          this.showProgressCases.push(el);
        }
      });
    },
  },
  methods: {
    createProgressNumber(val) {
      return Math.round(val / 5) * 5;
    },
    closeWelcome() {
      this.showWelcome = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.p-progressbar {
  border-radius: 100px;
}

.subTitle {
  padding-top: 0;
}
</style>
