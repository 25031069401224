<template>
  <DashboardAdmin
    v-if="
      checkRole([
        'admin',
        'certification-manager',
        'quality-assurance-manager',
        'product-manager',
      ])
    "
  ></DashboardAdmin>
  <DashboardClient v-else-if="checkRole(['client'])"></DashboardClient>
  <DashboardForAllRoles v-else></DashboardForAllRoles>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardAdmin from "../components/Dashboard/DashboardAdmin.vue";
import DashboardForAllRoles from "../components/Dashboard/DashboardAllRoles.vue";
import DashboardClient from "../components/Dashboard/DashboardClient.vue";
export default {
  components: {
    DashboardAdmin,
    DashboardForAllRoles,
    DashboardClient,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["checkRole"]),
  },
  mounted() {
    this.$action("dashboard-page-view");
  }
};
</script>

<style lang="scss" scoped>
.col {
  padding: 0;
}
</style>
