<template>
  <Card style="min-height: 15.6vh;">
    <template #title>{{ pageData.title }}</template>
    <template #content>
      <div v-if="loading" class="flex align-content-center m-1">
        <ProgressSpinner style="width: 50px; height: 50px" />
      </div>
      <div v-else-if="!loading && applications.length == 0">
        <b>{{ $t("dashboard.nothingAssigned") }}</b>
      </div>
      <div v-else-if="!loading && applications.length > 0">
        <div class="grid flex-column pt-3">
          <div
            class="col py-2 transition-colors transition-duration-500 hover:surface-200 border-round cursor-pointer"
            v-for="(item, index) in applications.slice(0, 5)"
            :key="index"
          >
            <router-link
              :to="
                checkRole(['sales'])
                  ? { path: '/createContracts', query: { appId: item.id } }
                  : { path: '/applications', query: { appId: item.id } }
              "
              custom
              v-slot="{ navigate, href }"
            >
              <div :href="href" @click="navigate">
                <div class="flex align-items-center">
                  <div
                    class="w-1rem h-1rem border-round mr-2"
                    style="background-color: #7d63f3"
                  ></div>
                  <b
                    >{{ item.companyName }} -
                    {{ item.companyRepresentative }}</b
                  >
                </div>
                <p class="opacity-40 ml-4">
                  {{
                    $dayjs(item.lastModifiedDate, "DD/MM/YYYY HH:mm:ss").format(
                      "HH:mm , DD MMM, YYYY"
                    )
                  }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </template>
    <template #footer v-if="!loading && applications.length > 0">
      <div class="text-right">
        <router-link :to="pageData.route">
          <Button :label="$t('dashboard.viewApplications')" />
        </router-link>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Applications dashboard",
  props: ["pageData"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["applications", "checkRole"]),
  },
  async mounted() {
    this.loading = true;
    if (this.checkRole(["head-application-reviewer", "application-reviewer"])) {
      await this.$store.dispatch("applications", { status: "new" });
    } else if (this.checkRole(["sales"])) {
      await this.$store.dispatch("applicationsForSales");
    }
    this.loading = false;
  },
};
</script>
