<template>
  <Card class="notifications">
    <template #title>{{ $t("dashboard.yourNotifications") }}</template>
    <template #content>
      <div v-if="loading" class="flex align-content-center m-1">
        <ProgressSpinner style="width: 50px; height: 50px" />
      </div>
      <div
        v-else-if="
          !loading &&
          (notifications.length == 0 ||
            notifications.filter((el) => el.readByAdmin == false).length == 0)
        "
      >
        <p>{{ $t("dashboard.noNotifications") }}</p>
      </div>
      <div
        v-else
        :class="notifications.length > 10 ? 'overflow-y-scroll' : ''"
        style="max-height: 90vh"
      >
        <div
          v-for="(notification, index) in notifications"
          :key="notification.id"
        >
          <NotificationMessage :notification="notification" :index="index">
          </NotificationMessage>
          <!-- <Message
            v-if="notification.readByAdmin == false"
            :severity="severity(notification)"
            @close="readNotification(notification, index)"
            ><div v-html="notification.message"></div></Message
          > -->
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
import NotificationMessage from "./NotificationMessage";
export default {
  name: "Dashboard notifications",
  components: {
    NotificationMessage,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["notifications", "userInfo"]),
  },
  async mounted() {
    await this.$store.dispatch("notifications", this.userInfo.sub);
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  min-height: 15.6vh;
}
</style>
