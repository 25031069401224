<template>
  <Card style="min-height: 15.6vh;">
    <template #title>{{ pageData.title }}</template>
    <template #content>
      <div v-if="loading" class="flex align-content-center m-1">
        <ProgressSpinner style="width: 50px; height: 50px" />
      </div>
      <div v-else-if="!loading && cases.length == 0">
        <b>{{ $t("dashboard.nothingAssigned") }}</b>
      </div>
      <div v-else-if="!loading && cases.length > 0">
        <div class="grid flex-column mt-3">
          <div
            class="col p-1 ml-3 mb-2 transition-colors transition-duration-500 hover:surface-200 border-round cursor-pointer"
            v-for="(item, index) in cases.slice(0, 5)"
            :key="index"
          >
            <router-link
              :to="{ path: pageData.route, query: { caseId: item.id } }"
              custom
              v-slot="{ navigate, href }"
            >
              <div :href="href" @click="navigate">
                <div class="flex align-items-center">
                  <div
                    class="w-1rem h-1rem border-round mr-2"
                    :style="{
                      'background-color': getColor(item.status),
                    }"
                  ></div>
                  <b
                    >{{ item.companyName }} -
                    {{ item.companyRepresentative }}</b
                  >
                </div>
                <p class="opacity-40 ml-4">
                  {{
                    $dayjs(item.lastModifiedDate, "DD/MM/YYYY HH:mm:ss").format(
                      "HH:mm , DD MMM, YYYY"
                    )
                  }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </template>
    <template
      #footer
      v-if="!loading && cases.length > 0"
    >
      <div class="text-right">
        <router-link :to="pageData.route">
          <Button :label="pageData.buttonWord" />
        </router-link>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "Audits dashboard",
  props: ["pageData"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["cases", "checkRole"]),
    ...mapState(["CaseStatuses"]),
  },
  async mounted() {
    this.loading = true;
    console.log(this.pageData)
    await this.$store.dispatch("cases", {status: this.pageData.status, invoiceStatus: this.pageData.invoiceStatus});
    this.loading = false;
  },
  methods: {
    getColor(status) {
      for (const el in this.CaseStatuses) {
        if (status == this.CaseStatuses[el].name) {
          return this.CaseStatuses[el].color;
        }
      }
    },
  },
};
</script>
