<template>
  <Card>
    <template #title> {{ $t("companyInformation.title") }} </template>
    <template #content>
      <div class="grid grid-nogutter">
        <div class="col-12 md:col-8">
          <div class="grid grid-nogutter flex-column">
            <div class="col">
              <Skeleton
                v-if="loading"
                width="20%"
                height="3rem"
                class="mb-2"
              ></Skeleton>
              <h3 v-else>
                {{
                  companyData ? companyData.companyName : $t("global.noValue")
                }}
              </h3>
            </div>
            <div class="col">
              <Skeleton
                v-if="loading"
                width="25%"
                height="1.2rem"
                class="mb-2"
              ></Skeleton>
              <b v-else>
                {{
                  companyData
                    ? `${companyData.address.street}, ${companyData.address.postalCode}, ${companyData.address.city}, ${companyData.address.country}`
                    : $t("global.noValue")
                }}
              </b>
            </div>
            <div class="col">
              <Skeleton
                v-if="loading"
                width="20%"
                height="1.2rem"
                class="mb-2"
              ></Skeleton>
              <b v-else>
                <template v-if="userId">
                  {{ userDataId.firstName }} {{ userDataId.lastName }}
                </template>
                <template v-else>
                  {{ userInfo.name }}
                </template>
              </b>
            </div>
            <div class="col">
              <Skeleton
                v-if="loading"
                width="15%"
                height="1.2rem"
                class="mb-2"
              ></Skeleton>
              <b v-else>
                {{
                  companyData
                    ? `${companyData.contact.telephone}`
                    : $t("global.noValue")
                }}
              </b>
            </div>
          </div>
        </div>
        <div v-if="$route.name == 'clientInformation'" class="col-12 md:col-4 mt-3 md:mt-0">
          <div id="map" style="width: 100%; height: 15vh"></div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
import { Loader } from "@googlemaps/js-api-loader";
export default {
  name: "Company information",
  data() {
    return {
      loading: true,
      userId: "userId" in this.$route.query ? this.$route.query.userId : "",
      infowindow: null,
    };
  },
  computed: {
    ...mapGetters(["clientInfo", "userInfo", "clientInfoId", "userDataId"]),
    companyData() {
      if (this.userId) {
        return this.clientInfoId;
      }

      return this.clientInfo;
    },
  },
  async mounted() {
    console.log(this.userId);
    // await Promise.all([
    //   ...(Object.keys(this.clientInfo).length == 0
    //     ? [
    //         this.userId
    //           ?
    //           : this.$store.dispatch("clientInfo"),
    //       ]
    //     : []),
    //   //       this.$store.dispatch("userInfo"),
    // ]);
    if (this.userId) {
      await this.$store.dispatch("userDataById", this.userId);
    }
    await this.$store.dispatch("clientInfo", { userId: this.userId });
    if (this.$route.name == "clientInformation") {
      const loader = new Loader({ apiKey: process.env["VUE_APP_GOOGLE_KEY"] });
      await loader.load();
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: {
          lat: 37.98381,
          lng: 23.727539,
        },
        zoom: 7,
      });
      if (
        this.companyData &&
        this.companyData.latitude &&
        this.companyData.longitude
      ) {
        this.$nextTick(() => {
          const marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              this.companyData.latitude,
              this.companyData.longitude
            ),
            animation: window.google.maps.Animation.DROP,
            map,
          });

          map.setZoom(17);
          map.panTo(marker.position);

          this.infowindow = new window.google.maps.InfoWindow({
            content: `${this.companyData.latitude}, ${this.companyData.latitude}`,
          });

          this.infowindow.open({
            anchor: marker,
            map,
          });
          marker.addListener("click", () => {
            // if (this.infowindow) {
            //   this.infowindow.close();
            // }

            this.infowindow.open({
              anchor: marker,
              map,
            });
          });
        });
      }
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.col {
  padding: 0;
  padding-left: 1.3%;
}

h3 {
  margin-bottom: 3%;
}
</style>
